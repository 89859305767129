<template>
	<div class="app-container">
		<el-form ref="postForm" :model="postForm" label-width="80px" label-position="top" class="mt-3" @submit.native.prevent>
			<div class="col-12">
                <el-button type="primary" @click="resetMemberPermission()" :loading="buttonloading">{{$t('button.reset_member_permission')}}</el-button>
                <el-button type="primary" @click="resetAdminPermission()" :loading="buttonloading">{{$t('button.reset_admin_permission')}}</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
import { getLocalStorage } from "@/system/store/localstorage";
import { postMethod } from "@/system/model/post/post";
import { getDAES, getEAES } from "@/system/library/security";

export default{
	data(){
		return {
			loading: true,
			currentJob: '',
            buttonloading: false,
            actionButton: false,
			permissionList: [],
			errors: [],
            submitForm:{
                security: '',
            },securityForm:{
                security: '',
                job_id:'',
			},postData: {
				data: '',
				language: ''
			},postForm: {
				security: ''
			},permission:{
                index:'RCAEQ4',
                DBsubmit:'PXO2AE',
            }
		}
	}, methods: {
		initial(){
			if(this.permissionList.includes(this.permission.index)){
				var self = this;
				this.postData.data = getEAES(JSON.stringify(this.submitForm));
				var result = postMethod('developer/permission/index',this.postData);
				result.then(function(value){

					if(!value.valid){
						self.onClose();
					}
					
					self.loading = false;
					self.buttonloading = false;
				});
			}
		},resetMemberPermission(){
			if(this.permissionList.includes(this.permission.DBsubmit)){
				this.buttonloading = true;
				this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.msg_reset_permission'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputType: 'password'
				}).then(({ value }) => {
					this.securityForm.security = value;
					this.securityForm.job_id = 4;
					this.postData.data = getEAES(JSON.stringify(this.securityForm));
					
					var self = this;
					var text = '';
					var result = postMethod('developer/permission/DBsubmit',this.postData);
					result.then(function(value){
						var data = JSON.parse(getDAES(value.data));
						
						if(value.valid){
							self.$message({
								type: 'success',
								message: data.returnMsg
							});
							
							self.securityForm.security = '';
						}else{
							self.errors = data.returnMsg;
							
							self.errors.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.popupErrorMessage(text);
						}
						
						self.buttonloading = false;
					});
				}).catch(() => {
					this.buttonloading = false;
				});       
			}
		},resetAdminPermission(){
			if(this.permissionList.includes(this.permission.DBsubmit)){
				this.buttonloading = true;
				this.$prompt(this.$t('msg.msg_security_password'), this.$t('msg.msg_reset_permission'), {
					confirmButtonText: this.$t('button.confirm'),
					cancelButtonText: this.$t('button.cancel'),
					inputType: 'password'
				}).then(({ value }) => {
					this.securityForm.security = value;
					this.securityForm.job_id = 3;
					this.postData.data = getEAES(JSON.stringify(this.securityForm));
					
					var self = this;
					var text = '';
					var result = postMethod('developer/permission/DBsubmit',this.postData);
					result.then(function(value){
						var data = JSON.parse(getDAES(value.data));
						
						if(value.valid){
							self.$message({
								type: 'success',
								message: data.returnMsg
							});
							
							self.securityForm.security = '';
						}else{
							self.errors = data.returnMsg;
							
							self.errors.forEach(function (value){
								text+= value+"<br/>";
							});
							
							self.popupErrorMessage(text);
						}
						
						self.buttonloading = false;
					});
				}).catch(() => {
					this.buttonloading = false;
				});   
			}				
		},popupErrorMessage(text){
			this.$alert(text, this.$t('msg.msg_error_title'), {
				type: 'error',
				dangerouslyUseHTMLString: true
			});
			
			this.errors = [];
		},resetPostData(){
			this.postData.data = '';
		},onClose(){
			this.$emit('closeCurrentTags');
		}
	}, created: function(){
		var currentLang = (getLocalStorage('currentLang')) ? getLocalStorage('currentLang') : 'en';
		this.permissionList = JSON.parse(getLocalStorage('permissionList'));
		this.postData.language = currentLang;
		this.initial();
	}
}
</script>